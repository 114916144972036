<template>
    <!-- сторінка відвали -->
      <PageHeader :title="title" :items="items" />
      
      <div class="row" v-if="this.perms[2000]">
        <button class="btn btn-success" @click="getDumps" style="margin-bottom: 10px;">{{ this.$t('Add') }}</button>
      </div>

      <!-- компонент таблицы -->
      <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :createdform="this.perms[101] || this.perms[2000]"
        :pages="objPages"
        :showpag="true"
        @create="modalShow = true" 
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @open="open" 
        @removeAll="removeAll"
        @getdata="getdata"
        @changelimit="changelimit"
      />
  
      <!-- карточка заявки -->
      <viewapplicdump 
        v-if="this.showModal == true"
        @close="closeModal" 
        @edit = "edit"
        :shortapplic="cardApplic" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @openUnregistered = "openUnregistered"
        @remove="remove"
        @getdata="getdata" 
        @toWork="toWork" 
        @toCancel="toCancel"
      ></viewapplicdump>

      <viewUnRegist
        v-if="showViewUnRegist == true"
        :objCard="this.objClient"
        @close = "showViewUnRegist = false"
      ></viewUnRegist>
  
      <!-- создание заявки -->
      <createBox 
        v-if="modalShow" 
        @close="modalShow = false; this.perm = false" 
        @save="save"
        @getData="this.getdata()"
        :perm = "this.perm"
        :obj ="this.form"
      ></createBox>
  
  </template>
  
  <script>
  import viewUnRegist from '@/views/accounts/unregistered/view/index'
  import PageHeader from "@/components/page-header";
  import tablecustom from '@/components/globaltable/index';
  import createBox from './create.vue'
  import { Dumps } from '@/API.js';
  import { storeS } from '@/store'
  import viewapplicdump from './view/index.vue'
  import { mutatePriority, mutateStatus } from '@/usabilityScripts/globalMutate.js'
  
  let apiServe = new Dumps();
  
  export default {
    components: {
      viewUnRegist,
      PageHeader,
      createBox,
      tablecustom,
      viewapplicdump
    },
    data() {
      return {
        objClient: '',
        showViewUnRegist: false,
        form: '',
        cardApplic:'',
        title: "dumps",
        objPages: {},
        dataParams: {
          status: true,
          page: "dumps"
        },
        objParams:{
          page: '1',
          pagelimit: '25',
          search: '',
          priority: '',
          status: '',
          pr1: '',
          pr2: '',
        },
        modalShow: false,
        showModal: false,
        ifNextopen: "",
        ifPrevopen: "",
        columns: [
          {
            name: "ID",
            text: "requestId",
            align: "left",
            status: true,
          },
          {
            name: this.$t("Status"),
            text: 'status',
            align: "left",
            status: true,
            mutate: (item, obj) => item == 'callNow' ? this.$t(this.mutateStatus(item, obj).name) : this.mutateStatus(item, obj).name,
            mutateClass: (item, obj) => this.mutateStatus(item, obj).color
          },
          /* {
            name: this.$t("priority"),
            text: "priority",
            align: "right",
            status: true,
            mutate: (item) => this.mutatePriority(item).name,
            mutateClass: (item) => this.mutatePriority(item).color
          }, */
          {
            name: this.$t("phone_num"),
            text: "userPhone",
            align: "right",
            status: true,
          },
          {
            name: this.$t("date_create"),
            text: "createDatetime",
            align: "right",
            status: true,
          },
          // {
          //   name: this.$t("company"),
          //   text: "company",
          //   align: "right",
          // },
        ],
        rows: [],   
        lastModals: {}
      };
    },
    created() {
      if(localStorage.getItem('filters '+this.dataParams.page) != null || localStorage.getItem('filters '+this.dataParams.page) != undefined) {
        JSON.parse(localStorage.getItem('filters '+this.dataParams.page)).forEach(element => {
          this.objParams[element.nameFilter] = element.value
        });
        // if(!JSON.parse(localStorage.getItem('filters '+this.dataParams.page)).find(el => el.nameFilter == 'pr')) {
          this.getdata()
        // }
      } else {
        this.getdata();
      }
    },
    methods: {  
      getDumps(){
        apiServe.importDumps().then(result => {
          if(result.status === 'done'){
            this.$toast.success(this.$t('Added'))
          }
        })
      },
      changeFilter(name, value) {
        if(name == 'pr') {
          this.objParams.pr1 = value[0]
          this.objParams.pr2 = value[1]
        }
        this.objParams[name] = value
        this.getdata()
      },
      comeback() {
        this.showViewUnRegist = false
        this.cardApplic = this.lastModals[0].form
        this.showModal = true
      },
      openUnregistered(client, modal) { 
        this.objClient = client
        this.lastModals = modal
        this.showViewUnRegist = true
      },
      closeModal(){
        this.showModal = false;
        storeS.checkModal.type = '';
        storeS.checkModal.id = '';
        this.getdata(this.objParams.page)
      },
      edit(e) {
        
        this.perm = true
        this.form = e
        this.modalShow = true
      },
      changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
      },
      getdata(e){
        
        this.objParams.page= e == undefined ? '' : e 
        // this.objParams.page = page != undefined ? page : '1';
        apiServe.getDumps(this.objParams).then(result => {
          if(result.status === 'done') {
            this.rows = result.data.items;
            this.objPages = result.data;
            for(var item in this.rows) {
              if(typeof this.rows[item].userPhone == 'object') {
                this.rows[item].userPhone = this.rows[item].userPhone.join(" ")
              }

              // впав сокет на зміну статусу на "Подзвонити зараз", костиль, юзати тільки на локалці

              // let obj = {
              //   requestIds: []
              // }

              // this.rows.forEach(el => {
              //   if(el.status == "delayed" && new Date(el.delayedDatetime) < new Date()) {
              //     obj.requestIds.push(el.requestId)
              //   }
              // })
              
              // if(obj.requestIds.length > 0) {
              //   apiServe.packCallNow(obj).then(result=> {
              //     if(result.status == 'done') {
              //       this.$toast.success(this.$t('success'))
              //     }
              //   })
              // }

              // впав сокет на зміну статусу на "Подзвонити зараз", костиль, юзати тільки на локалці
            }
          } else {
            this.$toast.error(this.$t('error') + ` #100`);
          }
        })
      },
      open: function(e){
        
        var id = e.requestId ? e.requestId : e;
        this.ifPrevopen = id == this.rows[0].requestId ? false : true;
        this.ifNextopen = id == this.rows[this.rows.length-1].requestId ? false : true;

        apiServe.getDump(e.requestId).then(result => {
          if(result.status === 'done') {
            this.cardApplic = result.data;
            this.showModal = true
          }
        })
      },
      searchB(e){
        this.objParams.search = e
        this.getdata()
      },
      change(name, e){
        if(name == 'Application_type'){
          this.selectApplication_type = e;
        } else if(name == 'priority_type'){
          this.selectpriority = e;
        } else if(name == 'Manager'){
          this.selectuser = e;
        }
      },
      removeAll: function(e){
        for(var item in e) {
          apiServe.deleteAllApplic(e[item]).then(res => {
            this.getdata();
            if(res){
              this.$toast.success(this.$t('Removed'));
              this.getdata();
            }
          })
        }
      },
      remove: function(e){
        apiServe.deleteRequest(e.id).then(result => {
          if(result.status=='done'){
            
            this.showModal = false;
            this.getdata();
          }
        })
        this.$toast.success(`report_sent `, e.length);
      },
      mutateStatus(e, obj){
        return mutateStatus(e, obj);
      },
      mutatePriority(e){
        return mutatePriority(e);
      },
      mutaterequest(e){
        var r = "";
        switch(e){
          case "request":
            r = this.$t('Application')
            break;
          case "rescheduled":
            r = this.$t('statusrescheduled')
            break;
          case "wait_delete":
            r = this.$t('statuswait_delete')
            break;
        }
        return {r};
      },
      clearFilter(){
        this.selectApplication_type = "0";
        this.selectpriority = "0";
        this.selectuser = "0";
      }
    },
    computed: {
      perms() {
        return storeS.perms
      },
      user(){
        return storeS.userbase
      },
    },
    mounted() {
      this.eventBus.off('saveDumps');
      this.eventBus.on('saveDumps', (status) => {
        if(status == true) {
          this.getdata();
        }
      })
      this.eventB.off('dumpGetData');
      this.eventB.on('dumpGetData', (status) => {
        if(this.user.userid != status.workerId) {
          // this.getdata();
        }
      })
    }
  };
  </script>
  
  <style scoped>
  .tr_etner:hover {
    cursor: pointer;
    background: #f3f3f9;
  }
  .howto_block {
    margin-left:10px;
  }
  .howto_block i {
    font-size: 23px;
    opacity: 0.5;
  }
  .howto_block i:hover{
    opacity: 1;
  }
  
  .howto_block {
    position: relative;
    display: inline-block;
  }
  
  .howto_block .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 10px;
  }
  
  .howto_block .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
  
  .howto_block:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  </style>
  
